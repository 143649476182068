const translate = {
  translations: [],
  locale: '',
  setTranslations(translations) {
    this.translations = JSON.parse(translations);
  },
  setLocale(locale) {
    this.locale = locale;
  },
  get(key, replace) {
    let translation = (this.translations[key]) ? this.translations[key] : key;
    if (replace) {
      Object.keys(replace).forEach((k) => {
        translation = translation.replace(`:${k}`, replace[k]);
      });
    }
    return translation;
  },
};

export default translate;
